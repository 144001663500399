// src/App.js
import { React, useEffect, useState, useRef, forwardRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Slide from '@mui/material/Slide';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import crypto from 'crypto-js';

import { socket } from './socket';
import DataGridAll from './datagrid.all';
import DataGridFuture from './datagrid.future';
import Dashboard from './dashboard';
import Reports from './reports';
import Users from './users';
import Commissions from './commissions';

var api = axios.create({ withCredentials: true });

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
  const { isLoading, isAuthenticated, error, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [current_user, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(0);
  const [customer_list, setCustomerList] = useState([]);
  const [current_broker, setCurrentBroker] = useState(null);
  const [tabval, setTabVal] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleDialogClickOpen = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogContent(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, value) => {
    setTabVal(value);
  };

  function encryptFullQueryString(queryString, privateKey) {
    const hash = crypto.HmacSHA256(queryString, privateKey);
    const calculatedAuth = crypto.enc.Hex.stringify(hash);
    return calculatedAuth;
  }

  function getQueryString() {
    var queryString = `payer=RevPro&preferredPayerEntity=RevPro&idap=${current_broker}&ts=${Math.floor(Date.now() / 1000)}`;
    const privateKey = process.env.REACT_APP_TIPALTI_PRIVATE_KEY;
    return queryString + `&hashkey=${encryptFullQueryString(queryString, privateKey)}`;
  }

  function getCurrentUser(controller) {
    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0AUDIENCE,
      scope: process.env.REACT_APP_AUTH0SCOPE
    }).then((token) => {
      api.get(
        `${process.env.REACT_APP_BASEURL}/api/users/user/${user.sub}`,
        { signal: controller.signal, headers: { Authorization: `Bearer ${token}` } }
      ).then((response) => {
        setCurrentUser(response.data);
        if (current_broker) {
          if (!response.data.company.includes('all')) {
            setCurrentBroker(response.data.company[0]);
          }
        } else {
          setCurrentBroker(response.data.company[0]);
        }
        socket.emit('join', response.data._id);
        if (response.data.admin === true) {
          socket.emit('join', 'admin');
        } else {
          socket.emit('leave', 'admin');
        }

        getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0AUDIENCE,
          scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
          api.post(
            `${process.env.REACT_APP_BASEURL}/api/dashboard/customers`,
            { company: response.data.company },
            { signal: controller.signal, headers: { Authorization: `Bearer ${token}` } }
          ).then((cresponse) => {
            var tmp = [];
            cresponse.data.forEach((record) => {
              tmp.push(record._id)
            });
            setCustomerList(tmp);
          }).catch((err) => {
            if (err.message !== 'canceled') {
              console.log(err);
            }
          });
        });
      }).catch((err) => {
        if (err.message !== 'canceled') {
          console.log(err);
        }
      });

    });
  }

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    if (isAuthenticated && isConnected) {
      getCurrentUser(controller);
      socket.on('user', () => { getCurrentUser(controller); });
    }

    return () => {
      socket.off('user');
      controller.abort();
    };
  }, [isConnected, isAuthenticated]);

  if (isLoading) {
    return (
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
      >
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  }
  if (error) {
    return (
      < Box
        component="main"
        sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }
        }
      > {error.message}
      </Box >
    );
  }

  if (isAuthenticated) {
    if (customer_list.length > 0 && current_user !== null && current_broker !== null) {
      var customers = customer_list;
      if (customers[0].buyer_broker_id !== 'all' && current_user.company.includes('all')) {
        customers.unshift({ buyer_broker_id: 'all', bought_from_name: 'Show All' });
      }
      return (
        <>
          <ToastContainer />
          <Backdrop
            sx={{ color: '#fff', zIndex: 10000000 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Dialog
            fullScreen
            open={openDialog}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleDialogClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {
                    dialogContent === 'user' ? 'User Management'
                      : dialogContent === 'commission' ? 'Commission Management'
                        : dialogContent === 'payee' ? 'Payout Configuration'
                          : dialogContent === 'invoices' ? 'Invoice History'
                            : 'Payment History'
                  }
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              {current_user.admin && dialogContent === 'user' && <Users isConnected={isConnected} user={current_user} />}
              {current_user.admin && dialogContent === 'commission' && <Commissions isConnected={isConnected} user={current_user} />}
              {
                current_broker !== 'all' &&
                dialogContent === 'payee' &&
                <iframe
                  src={`${process.env.REACT_APP_TIPALTI_BASEURL}/payeedashboard/home?${getQueryString()}`}
                  title="Payout Configuration"
                  width="100%"
                  height="100%"
                  style={{ border: 'none' }}
                />
              }
              {
                current_broker !== 'all' &&
                dialogContent === 'invoices' &&
                <iframe
                  src={`${process.env.REACT_APP_TIPALTI_BASEURL}/PayeeDashboard/Invoices?${getQueryString()}`}
                  title="Invoice History"
                  width="100%"
                  height="100%"
                  style={{ border: 'none' }}
                />
              }
              {
                current_broker !== 'all' &&
                dialogContent === 'payments' &&
                <iframe
                  src={`${process.env.REACT_APP_TIPALTI_BASEURL}/PayeeDashboard/PaymentsHistory?${getQueryString()}`}
                  title="Payment History"
                  width="100%"
                  height="100%"
                  style={{ border: 'none' }}
                />
              }
            </DialogContent>
          </Dialog>

          <Paper elevation={5}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
              <Grid item xs={12} sm={3} align={'left'}>
                <Box
                  component="img"
                  sx={{
                    height: 64,
                    width: 104.84,
                    marginLeft: '20px'
                  }}
                  alt="RevPro Logo"
                  src='/images/revpro_logo.png'
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Grid container sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
                  <Grid item xs={10} md={11} align={'right'}>
                    {
                      customers.length > 1
                        ?
                        <Autocomplete
                          options={customers}
                          getOptionLabel={(option) => option.bought_from_name}
                          getOptionKey={(option) => option.buyer_broker_id}
                          defaultValue={customers[0]}
                          disableClearable={true}
                          onChange={(event, newValue) => {
                            setCurrentBroker(newValue.buyer_broker_id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vendor Search"
                            />
                          )}
                          size="small"
                          sx={{ width: '300px' }}
                        />
                        :
                        <Typography variant="h5" sx={{ color: '#3B3061' }}>{customers[0].bought_from_name}</Typography>
                    }
                  </Grid>
                  <Grid item xs={2} md={1} align={'center'}>
                    <Tooltip title="Account Info">
                      <IconButton onClick={handleClick} color='success' size='small'>
                        <AccountCircleRoundedIcon fontSize='large' />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem disabled>{`${current_user.fname} ${current_user.lname}`}</MenuItem>
                      <MenuItem disabled sx={{ fontSize: '.75rem' }}>{`${current_user.email}`}</MenuItem>
                      <Divider />
                      {
                        current_broker !== 'all'
                          ?
                          <>
                            <MenuItem onClick={() => handleDialogClickOpen('payee')}>
                              <ListItemIcon>
                                <AssuredWorkloadRoundedIcon fontSize="small" color='primary' />
                              </ListItemIcon>
                              Payout Configuration
                            </MenuItem>
                            {/*
                            <MenuItem onClick={() => handleDialogClickOpen('invoices')}>
                              <ListItemIcon>
                                <ReceiptRoundedIcon fontSize="small" color='primary' />
                              </ListItemIcon>
                              Invoice History
                            </MenuItem>
                            */}
                            <MenuItem onClick={() => handleDialogClickOpen('payments')}>
                              <ListItemIcon>
                                <PaymentsRoundedIcon fontSize="small" color='primary' />
                              </ListItemIcon>
                              Payment History
                            </MenuItem>
                            <Divider />
                          </>
                          :
                          <></>
                      }
                      {
                        current_user.admin
                          ?
                          <>
                            <MenuItem onClick={() => handleDialogClickOpen('user')}>
                              <ListItemIcon>
                                <GroupsRoundedIcon fontSize="small" color='primary' />
                              </ListItemIcon>
                              User Management
                            </MenuItem>
                            <MenuItem onClick={() => handleDialogClickOpen('commission')}>
                              <ListItemIcon>
                                <CurrencyExchangeRoundedIcon fontSize="small" color='primary' />
                              </ListItemIcon>
                              Commission Management
                            </MenuItem>
                            <Divider />
                          </>
                          :
                          <></>
                      }
                      <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                        <ListItemIcon>
                          <LogoutRoundedIcon fontSize="small" color='error' />
                        </ListItemIcon>
                        LogOut
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TabContext value={tabval}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto">
                  <Tab label="Dashboard" value={0} />
                  <Tab label="Upcoming Events" value={1} />
                  <Tab label="All Events" value={2} />
                  <Tab label="Report Subscriptions" value={3} />
                </TabList>
              </Box>
              <TabPanel value={0}><Dashboard isConnected={isConnected} current_broker={current_broker} /></TabPanel>
              <TabPanel value={1}><DataGridFuture isConnected={isConnected} current_broker={current_broker} /></TabPanel>
              <TabPanel value={2}><DataGridAll isConnected={isConnected} current_broker={current_broker} /></TabPanel>
              <TabPanel value={3}><Reports isConnected={isConnected} user={current_user} /></TabPanel>
            </TabContext>
          </Paper >
        </>
      );
    } else {
      return (
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
        >
          <CircularProgress size={60} thickness={4} />
        </Box>
      );
    }
  } else {
    loginWithRedirect();
  }
}

export default App;