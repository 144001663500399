
import { React, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, Backdrop, CircularProgress, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
    DataGridPro,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import axios from 'axios';
import dayjs from 'dayjs';

import { socket } from './socket';

var api = axios.create({ withCredentials: true });

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
}).format;

const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

function Commissions(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [broker_loading, setBrokerLoading] = useState(false);
    const [columns, setColumns] = useState(null);
    const [rows, setRows] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [batches, setBatches] = useState([]);
    const [data, setData] = useState([]);
    const user = props.user;
    const isConnected = props.isConnected;

    const handleSelectionChange = (newSelection) => {
        const validSelection = newSelection.filter((rowId) => {
            const row = rows.find((r) => r.id === rowId);
            if (row.totalPoBalanceDue <= 0) {
                toast.error(`Invalid Amount : ${currencyFormatter(row.totalPoBalanceDue)}`);
                return false;
            }
            return true;
        });
        setSelectedRows(validSelection);
    };

    const handleDialogOpen = () => {
        if (selectedRows.length === 0) {
            toast.error('No Payments Selected');
            return;
        }
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleSubmit = async () => {
        if (selectedRows.length === 0) {
            toast.error('No Payments Selected');
            return;
        }

        setBrokerLoading(true);
        setOpenDialog(false);
        try {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            });

            const selectedData = selectedRows.map((rowId) => rows.find((r) => r.id === rowId));

            await api.post(
                `${process.env.REACT_APP_BASEURL}/api/batches/send`,
                { data: selectedData },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            toast.success('Payment Batch Successful.');
            setSelectedRows([]);
            handleDialogClose();
        } catch (error) {
            if (error.status === 400) {
                if (error.response.data.unmatched.length > 0) {
                    error.response.data.unmatched.forEach((element) => {
                        toast.error(element.buyerId + ' : Not In Tipalti');
                    });
                } else if (error.response.data.unpayable.length > 0) {
                    error.response.data.unpayable.forEach((element) => {
                        toast.error(element.refCode + ' : Incomplete Tipalti Setup');
                    });
                } else {
                    toast.error('Payment Server Error');
                }
            } else {
                toast.error('Payment Server Error');
            }
        } finally {
            setBrokerLoading(false);
        }
    };

    const fetchBatches = async () => {
        try {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            });

            const response = await api.get(
                `${process.env.REACT_APP_BASEURL}/api/batches/all`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setBatches(response.data);
        } catch (error) {
            console.error('Error fetching batches:', error);
            toast.error('Error fetching batches.');
        }
    };

    const getData = async (controller) => {
        try {
            const token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0AUDIENCE,
                scope: process.env.REACT_APP_AUTH0SCOPE
            });

            const response = await api.get(
                `${process.env.REACT_APP_BASEURL}/api/datagrid/commissions/2024-10-14`,
                { signal: controller.signal, headers: { Authorization: `Bearer ${token}` } }
            );

            const data = response.data.map((item, index) => ({
                id: index,
                paystart: dayjs(item._id.period_start.replace(/[Z]$/g, '')).toDate(),
                payend: dayjs(item._id.period_end_date.replace(/[Z]$/g, '')).toDate(),
                paydate: dayjs(item._id.pay_date.replace(/[Z]$/g, '')).toDate(),
                buyerId: item._id.buyer_broker_id,
                buyerName: item.bought_from_name,
                totalTicketCost: item.total_ticket_cost,
                totalConsignmentFees: item.total_consignment_fees_per_ticket,
                totalPoPayment: item.total_po_payment_per_ticket,
                totalPoBalanceDue: item.total_po_balance_due_per_ticket
            }));

            setData(data);
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Error fetching data:', error);
                toast.error('Error fetching data.');
            }
        }
    };

    const createRowsAndColumns = () => {
        const rows = data.map((item, index) => {
            const status = getStatus(item);
            const amount = getAmount(item);
            return {
                id: index,
                paystart: item.paystart,
                payend: item.payend,
                paydate: item.paydate,
                buyerId: item.buyerId,
                buyerName: item.buyerName,
                totalTicketCost: item.totalTicketCost,
                totalConsignmentFees: item.totalConsignmentFees,
                totalPoPayment: item.totalPoPayment,
                totalPoBalanceDue: item.totalPoBalanceDue,
                status: status,
                amount: amount
            };
        });

        const columns = [
            { field: 'paystart', headerName: 'Pay Period Start', flex: .35, minWidth: 100, type: 'date' },
            { field: 'payend', headerName: 'Pay Period End', flex: .35, minWidth: 100, type: 'date' },
            { field: 'paydate', headerName: 'Pay Date', flex: .35, minWidth: 100, type: 'date' },
            { field: 'buyerId', headerName: 'Buyer/Payee ID', flex: .25, minWidth: 100, type: 'string' },
            { field: 'buyerName', headerName: 'Bought From Name', flex: .5, minWidth: 100, type: 'string' },
            {
                field: 'totalTicketCost', headerName: 'Ticket Cost', flex: .25, minWidth: 100, type: 'number',
                renderCell: (params) => {
                    return `${currencyFormatter(params.value)}`;
                }
            },
            {
                field: 'totalConsignmentFees', headerName: 'Commission', flex: .25, minWidth: 100, type: 'number',
                renderCell: (params) => {
                    return `${currencyFormatter(params.value)}`;
                }
            },
            {
                field: 'totalPoPayment', headerName: 'PO Payments', flex: .25, minWidth: 100, type: 'number',
                renderCell: (params) => {
                    return `${currencyFormatter(params.value)}`;
                }
            },
            {
                field: 'totalPoBalanceDue', headerName: 'PO Balance Due', flex: .25, minWidth: 100, type: 'number',
                renderCell: (params) => {
                    return `${currencyFormatter(params.value)}`;
                }
            },
            { field: 'status', headerName: 'Status', flex: .4, minWidth: 100, type: 'string' },
            {
                field: 'amount', headerName: 'Amount Sent', flex: .25, minWidth: 100, type: 'number',
                renderCell: (params) => {
                    return `${currencyFormatter(params.value)}`;
                }
            }
        ];

        setRows(rows);
        setColumns(columns);
    };

    const getStatus = (item) => {
        let latestBatch = null;

        for (const batch of batches) {
            const requestData = batch.requestData.find(req =>
                dayjs(req.paystart).isSame(dayjs(item.paystart)) &&
                dayjs(req.payend).isSame(dayjs(item.payend)) &&
                dayjs(req.paydate).isSame(dayjs(item.paydate)) &&
                req.buyerId === item.buyerId
            );

            if (requestData) {
                if (!latestBatch || dayjs(batch.batchSummary.submissionDateTime).isAfter(dayjs(latestBatch.batchSummary.submissionDateTime))) {
                    latestBatch = batch;
                }
            }
        }

        if (latestBatch) {
            const matchedPayee = latestBatch.matchedPayees.find(payee => payee.refCode === item.buyerId.toString());
            if (matchedPayee) {
                const payment = latestBatch.payments.find(payment => payment.payeeId === matchedPayee.id);
                if (payment) {
                    return payment.status;
                }
            }
            return latestBatch.batchSummary.status;
        }

        return 'N/A';
    };

    const getAmount = (item) => {
        let latestBatch = null;

        for (const batch of batches) {
            const requestData = batch.requestData.find(req =>
                dayjs(req.paystart).isSame(dayjs(item.paystart)) &&
                dayjs(req.payend).isSame(dayjs(item.payend)) &&
                dayjs(req.paydate).isSame(dayjs(item.paydate)) &&
                req.buyerId === item.buyerId
            );

            if (requestData) {
                if (!latestBatch || dayjs(batch.batchSummary.submissionDateTime).isAfter(dayjs(latestBatch.batchSummary.submissionDateTime))) {
                    latestBatch = batch;
                }
            }
        }

        if (latestBatch) {
            const matchedPayee = latestBatch.matchedPayees.find(payee => payee.refCode === item.buyerId.toString());
            if (matchedPayee) {
                const payment = latestBatch.payments.find(payment => payment.payeeId === matchedPayee.id);
                if (payment) {
                    return payment.amountSubmitted.amount;
                }
            }
        }

        return 0;
    };

    const isRowSelectable = (params) => {
        const selectableStatuses = ['N/A', 'CANCELED', 'FAILURE', 'REJECTED', 'REJECTED', 'DEFERRED', 'DEFERRED_INTERNAL'];
        return selectableStatuses.includes(params.row.status) && params.row.totalPoBalanceDue > 0;
    };

    useEffect(() => {
        const controller = new AbortController();

        if (isConnected) {
            fetchBatches();
            getData(controller);

            socket.on('data', () => { getData(controller); });
            socket.on('batches', fetchBatches);
        }

        return () => {
            socket.off('data');
            socket.off('batches', fetchBatches);
            controller.abort();
        };
    }, [isConnected]);

    useEffect(() => {
        if (data.length > 0) {
            createRowsAndColumns();
        }
    }, [data, batches]);

    if (user.admin) {
        if (columns !== null && rows !== null) {
            return (
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 10000000 }}
                        open={broker_loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Button onClick={handleDialogOpen}>
                        Send Selected Payments
                    </Button>
                    <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
                        <DialogContent>
                            <DialogContentText>Selected Payments:</DialogContentText>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Buyer ID</TableCell>
                                            <TableCell>Buyer Name</TableCell>
                                            <TableCell>Payment</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedRows.map((rowId) => {
                                            const row = rows.find((r) => r.id === rowId);
                                            return (
                                                <TableRow key={rowId}>
                                                    <TableCell>{row.buyerId}</TableCell>
                                                    <TableCell>{row.buyerName}</TableCell>
                                                    <TableCell>{currencyFormatter(row.totalPoBalanceDue)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose}>Close</Button>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                        </DialogActions>
                    </Dialog>
                    <Paper elevation={1}>
                        <div style={{ height: '80vh', width: '100%' }}>
                            <DataGridPro
                                slots={{
                                    toolbar: GridToolbar
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 }
                                    }
                                }}
                                rows={rows}
                                columns={columns}
                                pagination
                                checkboxSelection
                                disableRowSelectionOnClick
                                rowSelectionModel={selectedRows}
                                onRowSelectionModelChange={handleSelectionChange}
                                isRowSelectable={isRowSelectable}
                            />
                        </div>
                    </Paper>
                </>
            );
        } else {
            return (
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
                >
                    <CircularProgress size={60} thickness={4} />
                </Box>
            );
        }
    } else {
        return (
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
            >
                <WarningRoundedIcon size={60} thickness={4} />
                <p>Not Authorized</p>
            </Box>
        );
    }
}

export default Commissions;